import axios from 'axios'
import env from 'configuration'
import { toast } from 'react-toastify'
import { removeAuth } from './auth-helper'

const BASE_URL = `${env.API_URL}/v1`

export default async function Api(path, method, option) {
  const url = BASE_URL + path
  let config = {}
  if (option?.headers) config.headers = option.headers
  return axios[method](url, option?.payload, config)
    .then((response) => {
      if (option?.notify) toast.success(response.data?.message)

      return { response: response.data }
    })
    .catch((error) => {
      if (
        typeof error?.message == 'string' &&
        error?.message?.includes('401')
      ) {
        toast.error('Your session is expired, login again to continue')
        // removeAuth()
        // setTimeout(() => {
        //   window.location.reload()
        // }, 5000)
      }
      if (option?.notify)
        toast.error(error.response?.data?.message || 'Something went wrong')
      return { error: error.response ? error.response.data : null }
    })
}
