const TYPES = {
  // AMOUNT: 'amount_coupon',
  // GIVEAWAY: 'giveaway_coupon',
  // COMPETITION: 'competition_coupon',
  // BIRTHDAY: 'birthday_coupon',
  // BUY1_GET1: 'buy_one_get_one',
  // STAMP: 'stamp_card',
  // BUSINESS: 'business_card',
  // MEMBERSHIP: 'membership_card',
  // COVID: 'covid_card',
  // GIFT: 'gift_card',
  // EVENT: 'event_ticket',
  // APPOINTMENT: 'appointment',
  // WEBINAR: 'webinar',
  // EVER_GREEN_WEBINAR: 'ever_green_webinar',
  // VISITING_STAMP: 'visiting_stamp',
  CUSTOM_STAMP: 'custom_stamp',
  POINT: 'point_card',
}

const DISCOUNT_TYPE = {
  PERCENT: 'percent',
  VALUE: 'value',
}

const REWARD_TYPE = {
  DISCOUNT: 'discount',
  CAMPAIGN: 'campaign',
}
const EXPIRE_TYPE = {
  NEVER: 'never',
  IN_DAYS: 'in_days',
  ON_DATE: 'on_date',
}

const REDEMPTION_TYPE = {
  ANY_DAY: 'any_day',
  CUSTOM: 'custom',
}

const CAMPAIGN_QUANTITY_TYPE = {
  UNLIMITED: 'unlimited',
  LIMITED: 'limited',
}

const REDEMPTION_USAGE_TYPE = {
  SINGLE: 'single',
  MULTI: 'multi',
  UNLIMITED: 'unlimited',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
}

export {
  TYPES,
  DISCOUNT_TYPE,
  EXPIRE_TYPE,
  REDEMPTION_TYPE,
  CAMPAIGN_QUANTITY_TYPE,
  REDEMPTION_USAGE_TYPE,
  REWARD_TYPE,
}
